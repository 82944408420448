<template>
  <v-app class="playstation">
		<transition name="fade-in-out" appear>
			<router-view></router-view>
		</transition>
	</v-app>
</template>

<script>
export default {
  name: 'PlaystationApp',
}
</script>

<style>
</style>
