import { allowed_date_formats } from './config';

export const overrides = {
	en: {
		console: {
      page_title: 'PlayStation | Time for an upgrade',
			the_right_time: 'The right time',
			for_upgrade: 'to upgrade',
			do_you_desire_for_a_new_console_but_you_are_short_on_money:
				'Do you feel it´s time for a new console, but you need more money?',
			we_will_buyout_your_old_sony_playstation_console_money_will_be_send_to_your_account_and_the_new_can_be_yours:
				'We will buy out the old PlayStation console from you, send the money to your bank account and the new console can be yours!',
			copyright_text:
				'All content, all game titles, trademarks and / or modifications, trademarks, artwork and related illustrations are trademarks or copyrighted materials of their respective owners. All rights reserved.',
      sale_of_used_consoles: "Sale of used consoles",
      choose_console_model_accessories_or_game: 'Select your console model and number of controllers',
		},
		validators: {
			required: 'This field is mandatory.',
			is_number: 'This field can only contain digits.',
			invalid_bank_account_number: 'Invalid account number.',
			invalid_bank_code: 'Invalid bank code.',
			min_allowed_value: 'The lowest value allowed is: {minValue}.',
			invalid_email: 'Invalid email',
			valid_post_code: 'Value must be a valid zip code',
			is_newer_date_valid: 'Date must be later than today\'s date',
			is_date_valid:
				'Incorrect date format. Please use one of the following formats: ' +
				allowed_date_formats.join(', ')
		},
		buyout: {
			thankYou: {
				preSale: {
					title:
						'Informative pricing was sent to your e-mail address under the number <b>{0}</b>.',
					text: 'If you want to complete your purchase, stop by one of our stores.'
				}
			},
      partners: {
        where_you_can_buy: "Where you can buy PlayStation consoles and accessories"
      }
		}
	},
  sk: {
    console: {
      page_title: 'PlayStation | Čas na upgrade',
      choose_console_model_accessories_or_game: 'Vyber model a počet ovládačov',
      partners: 'Partneri akcie',
      contact: 'Kontakty',
      playstation_action_claim_main_part: "Túžiš po novej konzole? Práve teraz si môžeš znížiť cenu predajom svojej starej konzoly.",
      playstation_action_duration: "*Akcia platí od 22. 2. do 22. 3. 2023",
      advisor: {
        i_specialize: "Špecializujem sa na konzole Playstation. V prípade akýchkoľvek otázok sa na mňa neváhajte obrátiť, rada pomôžem.",
        more_info_1: "Více informácií o Osobnom predajcovi zistíte pri kliku na ",
        icon: "ikonku",
        more_info_2: ", ktorá sa nachádza ",
        Kratos: "vedľa boha vojny Kratosa",
      },
      receipt_subtitle: "Nahraj účtenku svojho nového PlayStationu 5",
      register_sn_of_new_xbox: "Registrácia S/N nového PlayStationu 5",
      imei_subtitle: "Zadaj S/N svojho nového Playstationu 5",
      controllers: "Ovládače navyše",
      controllers_quantity: "Počet ovládačov navyše",
      i_have_multiple_controllers: "Mám viac ako jeden ovládač",
			multiple_controllers_info: "Zadej počet ovládačov navyše. Upozorňujeme, že 1 ovládač je súčasťou balenia konzoly a jeho cena je už zahrnutá v kúpnej cene vašej starej konzoly."
    },
		validators: {
			is_date_valid:
				'Chybný formát dáta. Použite prosím jeden z týchto formátov: ' +
				allowed_date_formats.join(', '),
      invalid_bank_account_number: 'Nesprávny IBAN.',
		},
		buyout: {
      find_price_of_device: 'Zisti hodnotu svojho zariadenia',
			buyout_price: 'Tvoja výkupná cena',
      give_registration_number: 'Zadaj číslo svojej registrácie',
			thankYou: {
				preSale: {
          title:
            'Informatívne nacenenie bolo odoslané na Tvoju e-mailovú adresu pod číslom <b>{0}</b>.',
					text: 'Pokiaľ budeš chcieť výkup dokončiť, zastav sa na niektorú z našich predajní.'
				}
			},
      partners: {
        where_you_can_buy: "U ktorých môžeš zakúpiť konzole a príslušenstvo značky PlayStation"
      },
      contractForm: {
        bank_account_full_number: 'Číslo bankovného účtu (IBAN)',
      }
		}
	},
};
