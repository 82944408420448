import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './styles/entry.js';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { version } from '../package.json';
import { EVENT_BUS, EVENT_BUS_TYPES } from './event-bus';
import buyout_store, { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import Vuex from 'vuex';
import { buyout } from 'js-mp';
import Vuetify from 'vuetify';
import VueI18n from 'vue-i18n';
import console_store, { VUEX_CONSOLE } from '__store__/consoleBuyout';
import { config } from '@/config';
import { overrides } from './language_overrides';
import VueScrollTo from 'vue-scrollto';
import "./cookie-bar";

Vue.config.productionTip = false;

if (
	process.env.NODE_ENV === 'production' ||
	process.env.NODE_ENV === 'staging'
) {
	Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		release: 'playstation' + version,
		integrations: [
			new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
		],
		tracesSampleRate: process.env.NODE_ENV === 'staging' ? 1.0 : 0.65,
		environment: process.env.NODE_ENV
	});
}

Object.defineProperties(Vue.prototype, {
	/**
	 * Global Event bus
	 */
	$eventBus: {
		enumerable: false,
		configurable: false,
		writable: false,
		value: {
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			on: (event, callback) => EVENT_BUS.$on(event, callback),
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			once: (event, callback) => EVENT_BUS.$once(event, callback),
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			emit: (event, callback) => EVENT_BUS.$emit(event, callback),
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			off: (event, callback) => EVENT_BUS.$off(event, callback),

			type: EVENT_BUS_TYPES
		}
	}
});


Vue.use(Vuex);
Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueScrollTo);

const NS = VUEX_BUYOUT.NAMESPACE;
const CS = VUEX_CONSOLE.NAMESPACE;
let options = {
	modules: {}
};

options.modules[NS] = buyout_store;
options.modules[CS] = console_store;
export const store = new Vuex.Store(options);

const { vuetify, i18n } = buyout.plugins;

i18n.locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : config.language;
document.documentElement.setAttribute("lang", i18n.locale);
i18n.setNumberFormat("en", {currency: {
      style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,
    }});

for (const lang in overrides) {
	i18n.mergeLocaleMessage(lang, overrides[lang]);
}

new Vue({
	store,
	router,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app');
