export { state } from './_state';
export { getters } from './_getters';
export { mutations } from './_mutations';
export { actions } from './_actions';

import { state } from './_state';
import { getters } from './_getters';
import { mutations } from './_mutations';
import { actions } from './_actions';

export const VUEX_CONSOLE = {
	NAMESPACE: 'consoleBuyout'
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
