export const MUTATION_TYPES = {
	SET_USABLE_CONSOLES: 'setUsableConsoles',
	SET_CONTROLLERS_QUANTITY: 'setControllersQuantity',
	SET_GAMES_QUANTITY: 'setGamesQuantity',
	SET_CONTACT_FORM_IS_VALID: 'setContactFormIsValid',
	SET_GAME_DATA: 'setGameData',
	SET_CONTROLLER_DATA: 'setControllerData',
	SET_IS_QUANTITY_FORM_VALID: 'setIsQuantityFormValid',
  SET_IS_BUYOUT_ONLINE: 'setIsBuyoutOnline',
	SET_IS_REGISTRATION: 'setIsRegistration',
	SET_IS_LOGGED_IN: 'setIsLoggedIn',
	SET_BUYOUT_LOAD_ERROR: 'setBuyoutLoadError',
	SET_USER_BUYOUT_CODE: 'setUserBuyoutCode',
};
