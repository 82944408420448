import { buyout } from 'js-mp';
import extend from 'lodash/extend';
import router from '@/router';

const config_overrides = {
	currency: '€',
	language: 'sk',
	gaming_brand: 'sony',
	category_seo_name: 'gaming',
	router: router,
  isURLHandlingEnabled: true,
  international_phone_prefix: '+421',
  swap_first_country: true,
  default_bank_code: '0200',
  scrollToElements: false,
  minimal_buyout_price: 0.5,
};
export const allowed_date_formats = [
	'DD.MM.YYYY',
	'DD.MM.YY',
	'DD. MM. YYYY',
	'DD. MM. YY',
	'YYYY-MM-DD'
];
buyout.config = extend(buyout.config, config_overrides);
export const config = buyout.config;
